<template>
  <div class="row texts">
    <div class="col-md-12">
      <h2 class="title">Privacy policy</h2>
      <div><p class="text-left"><strong>WEB SITE PRIVACY STATEMENT</strong></p>
        <p class="text-left">
          This privacy statement has been created in order to demonstrate&nbsp;
          <b data-stringify-type="bold">ZenZero</b>
          &nbsp;commitment to privacy. Due to the sensitive nature of the information we collect from our users, including address and phone numbers,
          we believe it is important to satisfy strict privacy requirements.
        </p>
        <p class="text-left">
          All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.
        </p>
        <p class="text-left">
          The Website Policies and Terms &amp; Conditions may be changed or updated occasionally to meet the requirements and standards.
          Therefore the Customers’ are encouraged to frequently visit these sections in order to be updated about the changes on the website.
          Modifications will be effective on the day they are posted.
        </p>
        <p class="text-left"><b data-stringify-type="bold">Contacting the Web Site</b><br>
          If you have any questions about this privacy statement, the practices of this site, or your dealings with this Web site.</p>
        <ul>
          <li class="text-left">You can send email to via&nbsp;
            <a @click="setToRoute('contacts_company')" >contact us</a>
          </li>
          <li class="text-left">You can send mail to the following postal address:</li>
          <li class="text-left"><b>Zen Zero Payment Services Provider LLC</b></li>
          <li class="text-left">Office 606, Dusseldorf Business Point, Al Barsha 1, PO Box 103644, Dubai, UAE</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'PrivacyPolicy',
  setup (props, context) {
    const { setStorage } = inject('storage')
    const { setToRoute } = inject('history')

    setStorage('showBack', true)
    setStorage('overlay', false)

    return {
      setToRoute
    }
  }
}
</script>
