<template>
  <PayProviders :groups=groups and :providers=providers />
</template>

<script>
import PayProviders from '@/components/PayProviders.vue'
import { inject, onMounted, ref } from 'vue'
import axios from '@/utils/axios-http'
import { useRoute } from 'vue-router'

export default {
  name: 'HomeView',
  components: {
    PayProviders: PayProviders
  },
  setup (props, context) {
    const { setStorage } = inject('storage')
    const groups = ref()
    const providers = ref()
    const route = useRoute()

    setStorage('showBack', false)
    setStorage('amount', '')

    const getParentGroups = () => {
      axios.get(process.env.VUE_APP_API_URL + 'groups/parent_groups/' + route.params.groupId)
        .then(response => {
          if (response.data && response.data.groups) {
            groups.value = response.data.groups
            setStorage('overlay', false)
          }
        })
    }

    const getProviders = () => {
      axios.get(process.env.VUE_APP_API_URL + 'providers/groups/' + route.params.groupId)
        .then(response => {
          if (response.data && response.data.providers) {
            providers.value = response.data.providers
            setStorage('overlay', false)
            setStorage('showBack', true)
          }
        })
    }
    onMounted(() => {
      getProviders()
      getParentGroups()
    })
    return {
      groups,
      providers
    }
  }
}
</script>
