<template>
  <v-row class="mt-8" no-gutters justify="center">
    <v-col
      cols="5"
      sm="5"
      lg="3"
      v-for="denomination in storage.denominations"
      :key="denomination.denomination_id"
      :item="denomination"
      class="rounded-lg ma-2 pa-0 v-col-group"
    >
      <v-img
        :src="require('../assets/images/international_btn_360.png')"
        @click="setToPay(denomination)"
      >
        <div class="our font-weight-bold">{{ denomination.our }} AED</div>
        <div class="them" v-if="!denomination.description">{{ denomination.them }} {{ denomination.currency_out }}</div>
        <div class="description" v-if="denomination.description">
          <small>{{ denomination.description }}</small>
        </div>
      </v-img>
    </v-col>
  </v-row>
  <v-row class="mt-8" no-gutters justify="center">
    <v-col
      cols="12"
      align="center"
    >
      <v-btn
        :ripple="false"
        @click="prevHistory"
      >
        BACK
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { inject } from 'vue'
import router from '@/utils/router'

export default {
  name: 'PayDenominations',
  props: {
    denominations: {
      required: true
    }
  },
  setup (props, context) {
    const { prevHistory } = inject('history')
    const { storage, setStorage } = inject('storage')

    const setToPay = denomination => {
      setStorage('overlay', false)
      setStorage('amount', denomination.our)
      if (denomination.description) {
        setStorage('description', denomination.description)
      } else {
        setStorage('description', denomination.them + ' ' + denomination.currency_out)
      }
      router.push({ name: 'pay' })
    }

    return {
      setToPay,
      prevHistory,
      storage
    }
  }
}
</script>

<style scoped>
.our {
  min-height: 26%;
  max-height: 26%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  margin: 0 5% 0 5%;
  color: blue;
}
.them {
  min-height: 74%;
  max-height: 74%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  margin: 0 5% 0 5%;
}
.description {
  min-height: 74%;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  margin: 0 5% 0 5%;
  max-height: 2.7em;
  line-height: 1.25em;
  display: flex;
  overflow: hidden;
}
</style>
