<template>
  <div class="row texts">
    <div class="col-md-12">
      <h2 class="title">Terms and Conditions</h2>
      <div>
        <p class="text-left">
          Welcome to Zen Zero Payment Services Provider LLC  (ZenZero) Website.
          <b>Please read and review these Terms of Use carefully before accessing or using this Website. By accessing
            or using this site, you acknowledge that you have read, understood and agreed to the Terms of Use
            Agreement. If you do not agree to the Terms of Use, you may not access or use the site.</b>
        </p>
        <p class="text-left">
          CONTENT<br>
          <b>ZenZero</b> will use reasonable efforts to include accurate and up-to-date information on this
          Website. However, by accessing or linking to this Website, you assume the risk that this Website and the
          content it contains, or may in the future contain, may be incomplete, inaccurate, out of date, or may not
          meet your needs and requirements.<br>
          ZenZero assumes no obligation to update the content.
        </p>
        <p class="text-left">
          PRIVACY<br>
          <b>ZenZero</b> respects the privacy of its Website users. Please refer to <b>ZenZero</b>
          Privacy Policy, which discloses the privacy practices with respect to information that you may disclose or
          may otherwise be collected on this Website. Any changes to <b>ZenZero</b> Privacy Policy
          will be handled in accordance with the terms set forth therein.
        </p>
        <p class="text-left">
          AGE OF MAJORITY<br>
          <b>ZenZero</b> does not accept agreements and payments from persons under the legal age of 18 years.
          By submitting your account application, you confirm that you are over 18 years of age or your parent or
          legal guardian has agreed to accept this Agreement on your behalf.<br>
          <b></b>
        </p>
        <p class="text-left">
          <b>Security Capabilities &amp; Policy for Transmission of Payment</b><br>
          <b></b>
        </p>
        <p class="text-left">
          <b>What information do we collect and how do we use it? </b><br>
          When you place an order through this website, we need to know your name, e-mail and mobile number. This
          allows us to process and fulfill your order successfully.<br>
          <b></b>
        </p>
        <p class="text-left">
          <b>How does ZenZero protect customer information? </b><br>
          When you place orders, we use a secure server. The secure server software (SSL) encrypts all information
          you input before it is sent to us.<br>
          <b></b>
        </p>
        <p class="text-left">
          <b>Credit Card Details</b>:<br>
          All credit card payments are processed securely through Payment Gateway. All credit/debit cards details
          and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third
          parties.
        </p>
        <p class="text-left">
          THIRD-PARTY WEBSITES AND LINKS<br>
          This Website may contain links or references to other Websites maintained by third parties over whom
          <b>ZenZero</b> has no control. <b>ZenZero</b> is not responsible for the privacy practices
          or content of those sites. Similarly, this Website may be accessed from third-party links over which
          <b>ZenZero</b> has no control. <b>ZenZero </b>makes no warranties or representations of any
          kind as to the accuracy, currency, or completeness of any information contained in such Websites and shall
          have no liability for any damages or injuries of any kind arising from such content or information. The
          inclusion of any third-party link does not imply an endorsement or recommendation by <b>ZenZero</b>.
        </p>
        <p class="text-left">
          TRADEMARKS, COPYRIGHTS, AND RESTRICTIONS<br>
          This site is controlled and operated by <b>ZenZero</b>. All material on this site, including, but not
          limited to, images, illustrations, audio clips, and video clips, is protected by copyrights, trademarks, and
          other intellectual property rights that are owned and controlled by <b>ZenZero</b>, its related
          companies, or by other parties that have licensed their material to <b>ZenZero</b>. Material on this
          Website is solely for your personal, non-commercial use.
        </p>
        <p class="text-left">
          Such material may not be copied, reproduced, republished, modified, uploaded, posted,
          transmitted, or distributed in any way, including by e-mail or other electronic means, without the express
          prior written consent of <b>ZenZero</b>. Use of the materials on any other Website or networked computer
          environment, or use of the materials for any purpose other than personal, non-commercial use is a violation
          of <b>ZenZero</b> copyrights, trademarks and other proprietary rights, and is prohibited.
        </p>
        <p class="text-left">
          DISCLAIMERS<br>
          THIS WEBSITE AND THE INFORMATION AND MATERIALS ON THIS SITE ARE PROVIDED TO YOU “AS IS” WITHOUT WARRANTY OF
          ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.<br>
          THE INFORMATION CONTAINED HEREIN MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS.  <b>ZenZero</b>
           MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, AS TO THE ACCURACY, COMPLETENESS OR
          RELIABILITY OF ANY FACTS, ADVICE, OPINIONS, VIEWS, STATEMENTS, RECOMMENDATIONS OR OTHER INFORMATION
          DISPLAYED ON OR DISTRIBUTED THROUGH THE WEBSITE.<br>
          YOU ACKNOWLEDGE THAT ANY RELIANCE UPON ANY SUCH FACTS, ADVICE, OPINIONS, VIEWS, STATEMENTS, RECOMMENDATIONS,
          OR OTHER INFORMATION IS AT YOUR SOLE RISK AND THAT <b>ZenZero</b> DOES NOT GIVE TAX, LEGAL OR
          INVESTMENT ADVICE OR ADVOCATE THE PURCHASE OR SALE OF ANY SECURITY OR INVESTMENT.<br>
          <b>ZenZero</b> MAKES NO REPRESENTATION OR WARRANTY WHATSOEVER REGARDING THE SUITABILITY, FUNCTIONALITY,
            AVAILABILITY OR OPERATION OF THIS  WEBSITE. THIS WEBSITE MAY BE TEMPORARILY
          UNAVAILABLE DUE TO MAINTENANCE OR MALFUNCTION OF COMPUTER EQUIPMENT.<br>
          <b>ZenZero</b> DOES NOT WARRANT THAT THE WEBSITE WILL BE UNINTERRUPTED OR  ERROR-FREE, THAT
          DEFECTS IN  THE WEBSITE WILL BE CORRECTED, OR THAT THE WEBSITE OR THE SERVERS THAT MAKE
          THE WEBSITE AVAILABLE ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.<br>
          LIMITATIONS OF LIABILITY<br>
          UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL <b>ZenZero</b> BE LIABLE
          TO YOU FOR DAMAGES OF ANY KIND WHETHER BASED IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE, INCLUDING,
          WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL
          DAMAGES RESULTING FROM OR IN CONNECTION WITH THIS WEBSITE OR THE INFORMATION IT CONTAINS
          OR ANY ERRORS OR OMISSIONS IN THE TECHNICAL OPERATION OF THE WEB SITE, EVEN IF <b>ZenZero</b>
           HAS   BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES WHETHER CAUSED IN
          WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, OR THEFT OR
           DESTRUCTION OF, OR UNAUTHORIZED  ACCESS TO, THE WEBSITE.
        </p>
        <p class="text-left">
          GOVERNING LAWS<br>
          This Terms of Use Agreement and your use of the Website shall be governed by the laws of the United
          Arab Emirates and the Emirate of Dubai without regard to its conflicts of laws principles. Any
          dispute, claim, legal action or proceeding related to this Website shall be governed and construed in
          accordance with the laws of United Arab Emirates and shall be brought exclusively in a court of
          competent jurisdiction sitting in Dubai, United Arab Emirates.
        </p>
        <p class="text-left">
          MISCELLANEOUS<br>
          If any provision of this Terms of Use Agreement is held to be unlawful, void, or unenforceable, the remaining
          provisions of the agreement will remain in place.
        </p>
        <p class="text-left">
          CHANGES TO THESE TERMS<br>
          We reserve the right, at our complete discretion, to change these Terms of Use at any time by posting
          revised terms on the site. It is your responsibility to check periodically for any changes we may make to
          these Terms of Use. Your continued use of this site following the posting of changes to these terms or other
          policies means you accept the changes.<br>
          <b>Thank you for visiting our site.</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'TermsAndConditions',
  setup (props, context) {
    const { setStorage } = inject('storage')

    setStorage('showBack', true)
    setStorage('overlay', false)
  }
}
</script>
