<template>
  <PayProvider v-if="provider" :provider=provider />
</template>

<script>
import axios from '@/utils/axios-http'
import { useRoute } from 'vue-router'
import { inject, onMounted, ref } from 'vue'
import PayProvider from '@/components/PayProvider.vue'
import router from '@/utils/router'

export default {
  name: 'ProviderView',
  components: {
    PayProvider: PayProvider
  },
  setup (props, context) {
    const { storage, setStorage } = inject('storage')

    setStorage('showBack', true)

    const route = useRoute()
    Object.entries(route.query).forEach(([key, value]) => {
      setStorage(key, value)
    })
    router.push({ name: 'provider', params: { providerId: route.params.providerId } })

    const provider = ref()
    const getProvider = () => {
      axios.get(process.env.VUE_APP_API_URL + `providers/providers/${route.params.providerId}`)
        .then(response => {
          console.log(response.data)
          if (response.data && response.data.provider) {
            provider.value = response.data.provider
            setStorage('overlay', false)
            setStorage('provider', provider)
            setStorage('denominations', [])
            if (storage.provider.type_id === 2) {
              const denominations = ref()
              axios.get(process.env.VUE_APP_API_URL + `providers/denominations/${storage.provider.id}`)
                .then(response => {
                  if (response.data && response.data.denominations) {
                    denominations.value = response.data.denominations
                    setStorage('denominations', denominations)
                  }
                })
            }
            Object.entries(provider.value.fields).forEach(([key, value]) => {
              if (!storage['field' + value.id]) {
                setStorage('field' + value.id, '')
              }
            })
          }
        })
    }

    onMounted(() => {
      getProvider()
    })

    return {
      provider
    }
  }
}
</script>
