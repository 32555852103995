<template>
  <div class="row texts">
    <div class="col-md-12">
      <h2 class="title">Contacts</h2>
    </div>
    <div class="col-md-12">
      <address>
        <h5>
          Zen Zero Payment Services Provider LLC
        </h5>
        <p>
          Office 606, Dusseldorf Business Point, Al Barsha 1,<br>
          PO Box 103644, Dubai, UAE
        </p>
        <ul>
          <li>E-mail: <a href="mailto:info@zenzero.ae">info@zenzero.ae</a></li>
          <li>Phone: <a href="tel:+97145648489">+9714-5648489</a></li>
          <li>Fax: +9714-5648473</li>
        </ul>
      </address>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'ContactsCompany',
  setup (props, context) {
    const { setStorage } = inject('storage')

    setStorage('showBack', true)
    setStorage('overlay', false)
  }
}
</script>
