<template>
  <v-row class="mt-5">
      <v-col>
      <div class="text-h4 text-center font-weight-bold">Quick Pay & Recharge</div>
      </v-col>
  </v-row>
  <v-row class="mt-5">
      <v-col>
      <div class="text-h7 text-center text-grey">Take care of yours bills & recharge quickly - no login required!</div>
      </v-col>
  </v-row>
  <v-row class="mt-8" no-gutters justify="center">
      <v-col
        cols="5"
        sm="5"
        lg="3"
        v-for="provider in providers"
        :key="provider.id"
        :item="provider"
        class="rounded-lg ma-2 pa-0 v-col-group"
      >
        <v-img
          :src='provider.image_url'
          @click="setToProvider(provider.id)"
        />
      </v-col>
      <v-col
      cols="5"
      sm="5"
      lg="3"
      v-for="group in groups"
      :key="group.group_id"
      :item="group"
      class="rounded-lg ma-2 pa-0 v-col-group"
      >
      <v-img
          :src='group.image_url'
          @click="getProviders(group.group_id)"
      />
      </v-col>
  </v-row>
</template>

<script>
import router from '@/utils/router'
import { inject } from 'vue'

export default {
  name: 'PayGroups',
  props: {
    groups: {
      required: true
    },
    providers: {
      required: true
    }
  },
  setup (props, context) {
    const { setStorage } = inject('storage')
    const getProviders = groupId => {
      setStorage('overlay', true)
      router.push({ name: 'groups', params: { groupId: groupId } })
    }
    const setToProvider = providerId => {
      setStorage('overlay', true)
      router.push({ name: 'provider', params: { providerId: providerId } })
    }

    return {
      getProviders,
      setToProvider
    }
  }
}
</script>

<style scoped>
.v-col-group {
    height: 100%;
    object-fit: cover !important;
}
</style>
