<template>
  <v-app-bar
    app
  >
    <v-btn
      :ripple="false"
      v-show="storage.showBack"
      @click="prevHistory"
    >
      <v-icon
        start
        icon="west"
        class="font-30"
      ></v-icon>
    </v-btn>
    <v-btn
      :ripple="false"
      v-show="!storage.showBack"
    >
      <v-icon
        start
        icon="test"
        class="font-30"
      ></v-icon>
    </v-btn>
    <v-img
      :src="require('../assets/images/logo.png')"
      class="my-1"
      contain
      height="40"
      @click="setToRoute('home')"
    />
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-app-bar-nav-icon
          v-bind="props"
          class="font-40"
        ></v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('home')"
          >Quick Pay & Recharge</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('privacy_policy')"
          >Privacy Policy</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('terms_and_conditions')"
          >Terms & Conditions</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('cancellation_refund_policy')"
          >Cancellation & Refund Policy</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('delivery_and_shipping_policy')"
          >Delivery & Shipping Policy</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            @click="setToRoute('contacts_company')"
          >Contacts</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'PayHeader',
  setup () {
    const { prevHistory, setToRoute } = inject('history')
    const { storage } = inject('storage')

    return {
      storage,
      prevHistory,
      setToRoute
    }
  }
}
</script>
<style>
  .font-30 {
    font-size: 30px !important;
  }
  .font-40 i {
    font-size: 40px !important;
  }
  a.menu {
    color: black;
    text-decoration: none; /* no underline */
  }
</style>
