<template>
  <div class="row texts">
    <div class="col-md-12">
      <h2 class="title">Delivery &amp; Shipping Policy</h2>
      <div>
        <p class="text-left">
          ZenZero will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control)
          sanctions countries in accordance with the law of UAE
        </p>
        <p class="text-left">
          You may purchase Products through ZenZero Services using Visa or MasterCard. Any credit card, debit card
          or other payment method which may be used on the Applications must have a valid billing address and valid
          issuing bank or other payment services provider.
        </p>
        <p class="text-left">
          Upon receipt of a proper and complete request from you for Services, ZenZero will charge the payment method
          provided by you and will forward an electronic request to the relevant third party provider (e.g. mobile
          operator DU or Etisalat) to provide the Products in the amount transferred, for the benefit of the
          recipient nominated by you.
        </p>
        <p class="text-left">
          You authorise ZenZero to act upon any instruction to charge the payment method provided by you through the
          WebSite which has been transmitted using any authentication/identity verification process which you may
          require to be used in connection with the purchase of Products.
        </p>
        <p class="text-left">
          ZenZero is not required to undertake any additional authentication or identity verification measures other
          than those required by applicable law or as ZenZero deems appropriate and sufficient to protect against
          fraud or money laundering and to maintain the security and proper use of the WebSite to comply with any
          internal policy.
        </p>
        <p class="text-left">
          All charges concluded post successful authentication or identity verification are your sole responsibility
          and liability.
        </p>
        <p class="text-left">
          ZenZero shall accept liability for the non-execution or defective execution of Services purchased through
          the WebSite, subject to your adherence with these Terms, the proper use of the WebSite as instructed by
          ZenZero, and the absence of any misrepresentation, fraud or negligence by you. Such liability, if incurred,
          shall be strictly limited to the amount of the unexecuted or defective Services.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'DeliveryAndShippingPolicy',
  setup (props, context) {
    const { setStorage } = inject('storage')

    setStorage('showBack', true)
    setStorage('overlay', false)
  }
}
</script>
