<template>
  <PayGroups :groups=groups :providers=providers />
</template>

<script>
import PayGroups from '@/components/PayGroups.vue'
import { inject, onMounted, ref } from 'vue'
import axios from '@/utils/axios-http'

export default {
  name: 'HomeView',
  components: {
    PayGroups: PayGroups
  },
  setup (props, context) {
    const { setStorage } = inject('storage')
    const groups = ref()
    const providers = ref()

    setStorage('showBack', false)
    setStorage('amount', '')

    const getGroups = () => {
      axios.get(process.env.VUE_APP_API_URL + 'groups/groups')
        .then(response => {
          if (response.data && response.data.groups) {
            groups.value = response.data.groups
            setStorage('overlay', false)
          }
        })
    }
    const getProvidersWithoutGroup = () => {
      axios.get(process.env.VUE_APP_API_URL + 'providers/providers_without_group')
        .then(response => {
          if (response.data && response.data.providers) {
            providers.value = response.data.providers
            setStorage('overlay', false)
          }
        })
    }
    onMounted(() => {
      getGroups()
      getProvidersWithoutGroup()
    })
    return {
      groups,
      providers
    }
  }
}
</script>
