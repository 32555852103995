<template>
  <div class="row texts">
    <div class="col-md-12">
      <h2 class="title">Cancellation &amp; Refund Policy</h2>
      <div >
        <p class="text-left">All sales are final with no refund or exchange permitted.</p>
        <p class="text-left">
          We are not responsible for any purchase of Mobile Recharge for an incorrect mobile number.
          However, if in a transaction performed by you on the ZenZero, money has been charged to your card or bank
          account and a Mobile Recharge is not delivered please inform us at the earliest but no later than 3
          month from completion of the transaction via the Customer Care phone&nbsp;
          <a href="tel:+97145648489" target="_blank">+9714-5648489</a>
          &nbsp; or through the link on the Website
          <a @click="setToRoute('contacts_company')">Contacts</a>.
          Please include the following details the Mobile number, Operator name, Recharge value and Receipt Number.
        </p>
        <p class="text-left">
          We will investigate the incident and if it is found that the money was indeed charged from your card or
          bank account without the delivery of the Mobile Recharge then the money will be refunded to you within
          7 working days from the date of the request.
        </p>
        <p class="text-left">Refunds will be done only through the Original Mode of Payment</p>
        <p class="text-left">
          It may take 3-21 days for the money to show in your bank account depending on your bank’s policy.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'CancellationRefundPolicy',
  setup (props, context) {
    const { setStorage } = inject('storage')
    const { setToRoute } = inject('history')

    setStorage('showBack', true)
    setStorage('overlay', false)

    return {
      setToRoute
    }
  }
}
</script>
<style>
.texts .title {
  text-align: center;
  font: 700 33px Helvetica;
  margin: 0 0 25px;
  color: #424242;
  text-transform: uppercase;
}
.texts {
  color: #5e5d5d !important;
  margin: 0;
  min-width: 320px;
  position: relative;
  font: 1.75rem Helvetica, sans-serif;
  background-color: #fff;
  -webkit-text-size-adjust: none;
}
.texts b, .texts strong {
  font-weight: bold;
}
.texts p {
  margin: 0 0 22px;
  line-height: 2.25rem;
}
.texts ul {
  padding-left: 40px;
}
.texts a:visited, .texts a:hover {
  text-decoration: underline;
  color: #ef7c00;
}
.texts a {
  text-decoration: none;
  color: #ef7c00;
}
</style>
