<template>
  <v-footer app bottom absolute padless>
    <v-card
      elevation="0"
      rounded="0"
      width="100%"
      class="text-center"
    >
      <v-divider></v-divider>
      <v-card-text>
        <v-row
          justify="center"
          class="text-center"
        >
          <v-col cols="12" sm="8" md="6" lg="4" xl="4">
            <v-row>
              <v-col class="pa-2">
                <v-img
                  class="ml-auto v-col-pay"
                  :aspect-ratio="1"
                  :src="require('../assets/images/visa.png')"
                  max-height="40"
                />
              </v-col>
              <v-col class="pa-2">
                <v-img
                  class="ml-auto v-col-pay"
                  :aspect-ratio="1"
                  :src="require('../assets/images/mastercard.png')"
                  max-height="40"
                />
              </v-col>
              <v-col class="pa-2">
                <v-img
                  class="ml-auto v-col-pay"
                  :aspect-ratio="1"
                  :src="require('../assets/images/applepay.png')"
                  max-height="40"
                />
              </v-col>
              <v-col class="pa-2">
                <v-img
                  class="ml-auto v-col-pay"
                  :aspect-ratio="1"
                  :src="require('../assets/images/googlepay.png')"
                  max-height="40"
                />
              </v-col>
              <v-col class="pa-2">
                <v-img
                  class="ml-auto v-col-pay"
                  :aspect-ratio="1"
                  :src="require('../assets/images/samsungpay.png')"
                  max-height="40"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-img
                  class="ml-auto"
                  :aspect-ratio="1"
                  :src="require('../assets/images/vbv.svg')"
                  max-height="25"
                />
              </v-col>
              <v-col>
                <v-img
                  class="ml-auto"
                  :aspect-ratio="1"
                  :src="require('../assets/images/secure_mastercard.png')"
                  max-height="25"
                />
              </v-col>
          </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <strong>We accept payments online using Visa and MasterCard credit/debit card in AED</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'PayFooter'
}
</script>

<style scoped>
</style>
