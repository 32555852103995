<template>
  <router-view :key="$route.params[$route.meta.watchParam]"></router-view>
  <v-container>
    <v-row class="mt-5">
      <v-col>
        <div class="text-h4 text-center font-weight-bold">Quick Pay & Recharge</div>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <div class="text-h7 text-center text-grey">Take care of yours bills & recharge quickly - no login required!</div>
      </v-col>
    </v-row>
    <v-row class="mt-8" no-gutters justify="center">
      <v-col
        cols="5"
        sm="5"
        lg="3"
        v-for="provider in providers"
        :key="provider.id"
        :item="provider"
        class="rounded-lg ma-2 pa-0 v-col-provider"
      >
        <v-img
          :src='provider.image_url'
          @click="setToProvider(provider.id)"
        />
      </v-col>
      <v-col
      cols="5"
      sm="5"
      lg="3"
      v-for="group in groups"
      :key="group.group_id"
      :item="group"
      class="rounded-lg ma-2 pa-0 v-col-provider"
      >
      <v-img
          :src='group.image_url'
          @click="getParentGroups(group.group_id)"
      />
      </v-col>
    </v-row>
    <v-row class="mt-8" no-gutters justify="center">
      <v-col
        cols="12"
        align="center"
      >
        <v-btn
          @click="prevHistory"
        >
          BACK
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '@/utils/router'
import { inject } from 'vue'

export default {
  name: 'PayProviders',
  watch: {
    $route (to, from) {
      this.$router.go()
    }
  },
  props: {
    groups: {
      required: true
    },
    providers: {
      required: true
    }
  },
  setup (props, context) {
    const { prevHistory } = inject('history')
    const { setStorage } = inject('storage')
    const getParentGroups = groupId => {
      setStorage('overlay', true)
      router.push({ name: 'groups', params: { groupId: groupId } })
    }
    const setToProvider = providerId => {
      setStorage('overlay', true)
      router.push({ name: 'provider', params: { providerId: providerId } })
    }

    return {
      prevHistory,
      getParentGroups,
      setToProvider
    }
  }
}
</script>

<style scoped>
  .v-col-provider {
    height: 100%;
    object-fit: cover !important;
  }
</style>
